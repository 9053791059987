<template>
	<div 
	@click="productClicked(product)"
	class="model animate__animated animate__fadeIn">
		<div
		v-if="route_name == 'Order'"
		@click.stop="removeFromOrder"
		class="btn-remove-product">
			<i class="icon-cancel"></i>
		</div>

		<vue-load-image>
			<img
			slot="image"
			class="slide-img" 
			:src="image(product)" 
			:alt="app_name+'-'+product.name">
	        <b-spinner
			slot="preloader"
	        variant="primary"></b-spinner>
			<div slot="error">
				Imagen no encontrada
			</div>
		</vue-load-image>

		<div class="data">
			<p>
				<strong>
					{{ product.name }}
				</strong>
			</p>
			<p
			class="price">
				{{ price(product.price) }}
			</p> 
			<p
			class="description"
			v-if="product.description && show_description">
				{{ product.description }}
			</p>
			<div
			v-if="show_amount">
				<hr>
				<p>
					Cantidad: {{ product.amount }}
				</p> 
				<p> 
					Total: {{ price(product.price * product.amount) }}
				</p>
			</div>
		</div>
	</div>
</template>
<script>
import VueLoadImage from 'vue-load-image'

import products from '@/mixins/products'
import cookies from '@/mixins/cookies'
export default {
	props: {
		product: {
			type: Object,
		},
		show_description: {
			type: Boolean,
			default: true,
		},
		show_amount: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [products, cookies],
	components: {
		VueLoadImage,
	},
	methods: {
		removeFromOrder() {
			this.$store.commit('order/removeProduct', this.product)
			this.removeProductFromCookies(this.product)
		},
	}
}
</script>