<template>
	<b-row>
		<b-col
		md="11">
			<products></products>
			<total></total>
			<btn-make-order></btn-make-order>
		</b-col>
	</b-row>
</template>
<script>
// Components
import Products from '@/components/buyer/order/components/Products'
import Total from '@/components/buyer/order/components/Total'
import BtnMakeOrder from '@/components/buyer/order/components/BtnMakeOrder'
export default {
	components: {
		Products,
		Total,
		BtnMakeOrder,
	},
}
</script>