import cookies from '@/mixins/cookies'
export default {
	mixins: [cookies],
	computed: {
		products() {
			return this.$store.state.order.products
		},
		description: {
			get() {
				return this.$store.state.order.description
			},
			set(value) {
				this.$store.commit('order/setDescription', value)
			}
		},
		buyer() {
			return this.$store.state.order.buyer
		},
		total() {
			let total = 0
			this.products.forEach(item => {
				total += item.price * item.amount
			})
			return total
		},
	},
	methods: {
		makeOrder() {
			this.$store.commit('auth/setLoading', true)			
			this.$store.commit('auth/setMessage', 'enviando pedido')
			this.$api.post('orders', {
				products: this.products,
				description: this.description,
				buyer: this.buyer,
			})			
			.then(res => {
				this.$store.commit('auth/setLoading', false)			
				this.$store.commit('auth/setMessage', '')
				this.$store.commit('order/setProducts', [])
				this.clearOrderCookies()
				this.$router.push({name: 'Thanks'})
			})
			.catch(err => {
				console.log(err)
				this.$store.commit('auth/setLoading', false)			
				this.$store.commit('auth/setMessage', '')
				this.$toast.error('Error al enviar pedido')
			})
		},
		isProductInOrder(product) {
			let index = this.products.findIndex(item => {
				return item.id == product.id
			})
			return index != -1
		}
	}
}