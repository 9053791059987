<template>
	<div>
		<p class="title">
			Mi pedido
		</p>
		<div
		v-if="products.length"
		class="cont-models">
			<product-card
			v-for="product in products"
			:product="product"
			show_amount 
			:show_description="false"></product-card>
		</div>
		<p 
		v-else
		class="text-with-icon">
			<i class="icon-eye-slash"></i>
			No hay productos en tu pedido
		</p>
	</div>
</template>
<script>
import ProductCard from '@/components/products/components/list/cards/ProductCard'

import order from '@/mixins/order'
export default {
	mixins: [order],
	components: {
		ProductCard,
	}
}
</script>