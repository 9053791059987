<template>
	<p 
	v-if="products.length"
	class="title text-left">
		Total: {{ price(total) }}
	</p>
</template>
<script>
import order from '@/mixins/order'
export default {
	mixins: [order],
}
</script>