<template>
	<b-button
	v-if="products.length"
	:to="{name: 'ConfirmOrder', params: {view: 'telefono'}}"
	class="m-b-20"
	variant="primary"
	block>
		Confirmar pedido
	</b-button>
</template>
<script>
import order from '@/mixins/order'
export default {
	mixins: [order],
}	
</script>