export default {
	computed: {
		products() {
			return this.$store.state.products.models
		},
		to_show() {
			return this.$store.state.products.to_show
		},
		loading() {
			return this.$store.state.products.loading
		},
		display() {
			return this.$store.state.products.display
		},
	},
	methods: {
		productClicked(product) {
			if (this.route_name == 'AdminProducts') {
				this.edit(product)
			} else {
				this.$store.commit('product/setModel', product)
				this.$router.push({name: 'Product', params: {view: product.slug}})
            	this.$scrollToTop()
            	this.$store.commit('products/setToShow')
			}
		},
		edit(model) {
			this.$store.commit('products/setModel', model)
			this.$bvModal.show('product')
		},
		deleteModel(model) {
			this.$store.commit('products/setDelete', model)
			this.$bvModal.show('delete-product')
		},
	}
}